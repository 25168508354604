
import { defineComponent } from 'vue';

export default defineComponent({
  name: "PrintableContainer",
  props: {
    id: {type: Number, required: true},
    title: {type: String, required: true},
    image: {type: String, required: true}
  },
  methods: {
    toInfo() {
      this.$router.push('/printables/info/' + this.id)
    }
  }
})
