
import { defineComponent } from 'vue';
import PrintableContainer from "@/components/PrintableContainer.vue";
import GameContainer from "@/components/GameContainer.vue";
import apiService from "@/api/api";
import api from "@/api/api";

export default defineComponent({
  name: "Printables",
  components: {PrintableContainer,GameContainer},
  data() {
    const printables: any[] = []
    return {
      printables,
      testImage: 'https://framerusercontent.com/images/xjO2MpSWQs8XzUiqU5xb9jwxQ.png',
      testTitle: 'Children\'s doctor - Dentist'
    }
  },
  beforeMount() {
    apiService.getPrintables(this.$store.getters.URL, this.$store.getters.CURRENT_LANG_ID).then((r: any): any => {
      // console.log('PRINT RESP ', r)
      if (!r.data.error) {
        this.printables = r.data.printables || []
      }
    })
  },
  methods: {
    getSrc(image: string): string {
      return apiService.getImageSrc(image, 'printables')
    }
  }
})
